import React, { useMemo, useState } from "react";
import SubPageBase from "../components/SubPageBase";
import SearchBox from "../components/SearchBox";
import useAxios from "axios-hooks";
import CenterProgress from "../components/CenterProgress";
import JobCard from "../components/JobCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  content: {
    width: "80%",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridGap: "1.5vw",
    marginBottom: "5vh",
    marginTop: "5vh"
  }
});

const PositionsPage = () => {
  const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const [{ data, loading, error }] = useAxios("https://live.peoplise.com/peoplise/api/decathlon/positionlist");

  const { departments, locations } = useMemo(() => {
    const departments = new Set();
    const locations = new Set();
    data?.forEach(position => {
      departments.add(position.department);
      locations.add(position.location);
    });
    return { departments: [...departments], locations: [...locations] };
  }, [data]);

  const content = data && <>
    <SearchBox
      departments={departments}
      locations={locations}
      department={selectedDepartment}
      setDepartment={setSelectedDepartment}
      location={selectedLocation}
      setLocation={setSelectedLocation}
    />
    {data
      .filter(position => {
        return !(
          (selectedDepartment && selectedDepartment !== position.department) ||
          (selectedLocation && selectedLocation !== position.location)
        );
      })
      .map(position => (
        <JobCard job={position} key={position.id} />
      ))}
  </>;
  return (
    <SubPageBase title="TAKIMINI BUL" objectPosition="top">
      <div className={classes.content}>
        {loading && <CenterProgress />}
        {content}
      </div>
    </SubPageBase>
  );
};

export default PositionsPage;
