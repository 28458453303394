import React from "react";
import { colorPalette } from "./colors";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  searchBoxRoot: {
    gridColumn: "1/-1",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1vw"
  },

  formControl: {},

  sportAvatar: {
    margin: 4,
    height: 30,
    width: 30,
    display: "inline-block"
  },
  sportName: {},

  button: {
    background: colorPalette.orange,
    width: "100%",
    height: "100%",
    float: "right",
    cursor: "pointer"
  }
});

const SearchBox = ({
  department,
  setDepartment,
  location,
  setLocation,
  departments,
  locations
}) => {

  const classes = useStyles();

  return (
    <div className={classes.searchBoxRoot}>
      <FormControl className={classes.formControl}>
        <Select
          value={department}
          onChange={event => setDepartment(event.target.value)}
          displayEmpty
          input={
            <OutlinedInput
              labelWidth={0}
              name="location"
              id={"location-select"}
            />
          }
        >
          <MenuItem value="">Tüm Departmanlar</MenuItem>
          {departments.map(department => (
            <MenuItem value={department} key={department}>
              {department}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Select
          value={location}
          onChange={event => setLocation(event.target.value)}
          displayEmpty
          input={
            <OutlinedInput
              labelWidth={0}
              name="location"
              id={"location-select"}
            />
          }
        >
          <MenuItem value="">Tüm Konumlar</MenuItem>
          {locations.map(location => (
            <MenuItem value={location} key={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
  );
};

export default SearchBox;
